import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import * as serviceWorker from "serviceWorker";

/* connected-react-router */
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "reducers";

/* for aws amplify */
import client_config from "client-config";
import { GetAmplifyConfig } from "aws-config";
import Amplify from "aws-amplify";

/* user component */
import consoleLogger from "helpers/consoleLogger";
import App from "App";

// historyを構築
const history = createBrowserHistory();

// middle wareを構築
const middlewares = []
middlewares.push(routerMiddleware(history)); // for dispatching history actions
middlewares.push(thunk);

// redux-loggerを構築
if(process.env.NODE_ENV !== 'production') {
  const { createLogger } = require('redux-logger');
  const logger = createLogger({
    collapsed:true,
    diff:true
  });
  middlewares.push(logger);
}

// reducerを元にstoreを生成する
const store = createStore(
  //connectRouter(history)(rootReducer), // new root reducer with router state  rootReducer,
  createRootReducer(history), // root reducer with router state
  compose(
    applyMiddleware(
      ...middlewares
    ),
  ),
);

// Authの構築。
try {
  Amplify.configure({
    Auth: GetAmplifyConfig(client_config["client_id"])
  });
} catch (err) {
  consoleLogger('index-Amplify.configure-catch', err);
}

consoleLogger('index-REACT_APP_PUBLISH_ENV', process.env.REACT_APP_PUBLISH_ENV);
ReactDOM.render(
  <Provider store={store}>
    <App history={history}/>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
