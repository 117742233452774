let _client_config = [];
_client_config["prd"] = {
  'client_id': '7mfajadi44trod4p9rokmp071r',
  'region': 'ap-northeast-1',
  'AppWebDomain': 'bb-adm.auth.ap-northeast-1.amazoncognito.com',
  'UserPoolId': 'ap-northeast-1_n8wx9NIq0',
  'RedirectUriSignIn': '',
  'RedirectUriSignOut': '',
  'APIGatewayEndPoint': 'https://n97mn40jtk.execute-api.ap-northeast-1.amazonaws.com/prd'
}
_client_config["dev"] = {
  'client_id': 'l0vaah83ossluhd2252m6fe74',
  'region': 'ap-northeast-1',
  'AppWebDomain': '19bb-adm.auth.ap-northeast-1.amazoncognito.com',
  'UserPoolId': 'ap-northeast-1_BKHgkoZ1W',
  'RedirectUriSignIn': '',
  'RedirectUriSignOut': '',
  'APIGatewayEndPoint': 'https://jakgiw238h.execute-api.ap-northeast-1.amazonaws.com/dev'
}
const client_config = _client_config[process.env.REACT_APP_PUBLISH_ENV];

export default client_config;
